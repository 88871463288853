*{font-family: 'Inter', sans-serif;
 
}

.parent-box-1{
    overflow-x: scroll;
    overflow-y:scroll;
}

html {
    scroll-behavior: smooth;
  }